<template>
  <div
    class="list"
    :class="{
      mobile: mobileListing,
      'list--show-more': isShowMoreButton,
      'list--disabled': loading,
    }"
  >
    <div class="list__container">
      <div
        v-for="(item, index) in internalData"
        :key="getRowKey?.(item) || index"
        class="list__item"
      >
        <slot
          :item="item"
          :index="index"
        />
      </div>

      <LoaderButton
        v-if="isCatalogAvailable && isShowMoreButton"
        class="list-more__button"
        :loader="showMoreBtnParams?.loader"
        :icon-src="showMoreBtnParams?.iconSrc || EIconPath.IndicatorsProgressDarkSvg"
        :btn-classes="showMoreBtnParams?.btnClasses || ['btn-secondary', 'mm-font-500']"
        :button-text="showMoreBtnParams?.buttonText || `Показать ещё ${internalMoreNumber}`"
        :custom-disabler="disablePagination"
        @confirm="onClickLoadMore"
      />
      <div
        v-else-if="!isCatalogAvailable"
        class="list__text"
        disabled
      >
        {{ itemsShownText }}
      </div>
    </div>

    <Pagination
      v-if="internalData.length && isCatalogAvailable"
      ref="paginationRef"
      :page="internalPage"
      :size="pages"
      :disabled="disablePagination || showMoreBtnParams?.loader?.value"
      @page-changed="onPageChanged"
    />
  </div>
</template>

<script lang="ts" setup>
import Pagination from 'shared/components/Pagination.vue';
import LoaderButton from 'shared/components/LoaderButton.vue';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { paginationSkip } from 'shared/utils/paginationSkip.util';
import { IPaginationListShowMoreBtnProps } from 'models/paginationList.model';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { useCatalog } from 'composables/useCatalog';

const props = withDefaults(
  defineProps<{
    data?: Array<unknown>; // Массив значений
    action?: string; // api endpoint для погрузки новых значений
    numberPage?: number; // Количество страниц
    size?: number; // Количество элементов на странице
    page?: number; // Текущая страница
    getRowKey?(item): string | number; // Кастомный key для элемента в списке
    dataKey?: string; // Ключ значений в ответе с API
    sizeQuery?: string; // Наименование в query string количества элементов на странице для запроса API
    pageQuery?: string; // Наименование в query string текущей страницы для запроса API
    totalPageKey?: string; // Ключ количества страниц в ответе с API
    moreNumber?: number; // Количество элементов которые необходимо подгружать на нажатие кнопки
    dataCount?: number; // Общее количество значений
    dataCountKey?: string; // Ключ общего кол-ва данных в ответе
    skipQuery?: string; // Query параметр для смещения
    defaultParams?: Record<string, unknown>; // Параметры для запроса
    mobileListing?: boolean; // Флаг для стилизации под мобильный список
    showMoreBtnParams?: IPaginationListShowMoreBtnProps; // Параметры для кнопки "Показать ещё"
    disablePagination?: boolean; // Отключает пагинацию
    loading?: boolean; // режим загрузки асинхронных данных
  }>(),
  {
    size: 20,
    numberPage: 1,
    page: 1,
    dataKey: 'items',
    sizeQuery: 'pageSize',
    pageQuery: 'page',
    totalPageKey: 'totalPage',
    moreNumber: 20,
    dataCountKey: 'count',
    skipQuery: 'skip',
  },
);

const emit = defineEmits<{
  (e: 'loadMore', skip: number): void;
  (e: 'pageChanged', page: number, skip: number): void;
}>();

const { isCatalogAvailable } = useCatalog();

const internalPage = ref(props.numberPage);
const internalSize = ref(props.size);
const internalNumberPage = ref(props.numberPage);
const internalData = ref<Array<unknown>>([]);
const internalDataCount = ref(props.dataCount);
const loadingData = ref(false);
const paginationRef = ref<InstanceType<typeof Pagination>>();

const watchSubscription = new WatchSubscription();
const pages = computed(() => Math.ceil(internalDataCount.value / internalSize.value));
const diffDataCount = computed(() => internalDataCount.value - internalData.value.length);
const internalMoreNumber = computed(() => (diffDataCount.value > props.moreNumber ? props.moreNumber : diffDataCount.value));
const isShowMoreButton = computed(() => (props.moreNumber && !paginationRef.value?.isLastPage && internalMoreNumber.value > 0));

const itemsShownText = computed(() => `Показано ${internalData.value.length} из ${props.dataCount}`);

function onPageChanged(newPage: number): void {
  setPage(newPage);

  if (!props.action) {
    return emitPageChanged(newPage);
  }

  loadData(newPage);
}

function onClickLoadMore(): void {
  if (!props.action) {
    return emitLoadMore();
  }

  const newSize = internalSize.value + internalMoreNumber.value;
  setSize(newSize);
  setPage(1);
  loadData(internalPage.value, newSize);
}

async function loadData(page = internalPage.value, size = internalSize.value, params = props.defaultParams): Promise<void> {
  loadingData.value = true;
  try {
    const { data } = await useFetch(props.action, {
      params: {
        ...params,
        [props.skipQuery]: (page - 1) * size,
        [props.sizeQuery]: size,
      },
      initialCache: false,
    });

    if (!data.value) {
      return;
    }

    setInternalData(props.dataKey ? data.value[props.dataKey] : data.value);
    setInternalDataCount(data.value[props.dataCountKey]);
    setPage(page);
    setSize(size);
  } catch (error) {
    console.error(error);
  } finally {
    loadingData.value = false;
  }
}

function emitLoadMore(): void {
  emit('loadMore', paginationSkip(props.page + 1, props.size));
}

function emitPageChanged(newPage: number): void {
  emit('pageChanged', newPage, paginationSkip(newPage, props.size));
}

function setInternalData(newInternalData: Array<unknown>): void {
  internalData.value = newInternalData;
}

async function initData(): Promise<void> {
  if (props.data) {
    setInternalData(props.data);
    return;
  }

  if (props.action) {
    return loadData();
  }
}

function setPage(newPage: number): void {
  internalPage.value = newPage;
}

function setSize(newNumberPerPage: number): void {
  internalSize.value = newNumberPerPage;
}

function setNumberPage(newNumberPage: number): void {
  internalNumberPage.value = newNumberPage;
}

function setInternalDataCount(newDataCount: number): void {
  internalDataCount.value = newDataCount;
}

async function init(): Promise<void> {
  setNumberPage(props.numberPage);
  setPage(props.page);
  setSize(props.size);
  await initData();
}

watchSubscription.add(
  watch(
    () => props.data,
    (newData) => setInternalData(newData),
    { deep: true },
  ),

  watch(() => props.page, setPage),

  watch(() => props.numberPage, setNumberPage),

  watch(() => props.size, setSize),

  watch(() => props.dataCount, setInternalDataCount),
);

useSSRUnsubscribeWatch(watchSubscription);

await init();

defineExpose({
  loadData,
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.list {
  &__container {
    display: flex;
    flex-flow: row wrap;
    border-radius: 8px;
    width: 100%;
  }

  &__item {
    border-right: 1px solid $pagination-list-border-color;
    border-bottom: 1px solid $pagination-list-border-color;

    &:first-child:last-child {
      border-radius: 8px;
    }

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
    }

    &:hover {
      z-index: 1;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.7;
    transition: 0.2s linear;
  }

  &--show-more {
    .list__item:nth-child(5n):last-child,
    .list__item:nth-child(5n):nth-last-child(2),
    .list__item:nth-child(5n):nth-last-child(3),
    .list__item:nth-child(5n):nth-last-child(4),
    .list__item:nth-child(5n):nth-last-child(5) {
      border-bottom-right-radius: 0 !important;
    }

    .list-more__button {
      padding: 10px 28px;
      border: 1px solid $pagination-list-border-color;
      border-top-color: transparent;
      border-radius: 0 0 8px 8px;
    }
  }

  &__text {
    @include text-r14;

    display: flex;
    background: $color-bg-button-disabled;
    color: $color-secondary;
    justify-content: center;
    padding: 10px 28px;
    border: 1px solid $pagination-list-border-color;
    border-top-color: transparent;
    border-radius: 0 0 8px 8px;
    width: 100%;
  }
}

:deep(.list__item) {
  & > *:first-child {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 1600px) {
  .list__item {
    flex: 0 0 20%;
    min-height: 481px;
    width: 20%;

    &:nth-child(5n + 1) {
      border-left: 1px solid $pagination-list-border-color;
    }

    &:nth-child(-n + 5) {
      border-top: 1px solid $pagination-list-border-color;
    }

    &:nth-child(5n):last-child,
    &:nth-child(5n):nth-last-child(2),
    &:nth-child(5n):nth-last-child(3),
    &:nth-child(5n):nth-last-child(4),
    &:nth-child(5n):nth-last-child(5) {
      border-bottom-right-radius: 8px;
    }

    &:nth-child(5),
    &:nth-child(4):last-child,
    &:nth-child(3):last-child,
    &:nth-child(2):last-child {
      border-top-right-radius: 8px;
    }

    &:nth-child(5n-4):last-child,
    &:nth-child(5n-4):nth-last-child(2),
    &:nth-child(5n-4):nth-last-child(3),
    &:nth-child(5n-4):nth-last-child(4),
    &:nth-child(5n-4):nth-last-child(5) {
      border-bottom-left-radius: 8px;
    }

    &:nth-child(5n+5) {
      :deep(.mm-product-card) {
        padding-right: 32px;
      }
    }

    &:nth-child(5n+1) {
      :deep(.mm-product-card) {
        padding-left: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .list__item {
    flex: 0 0 25%;
    min-height: 481px;
    width: 25%;

    &:nth-child(4n + 1) {
      border-left: 1px solid $pagination-list-border-color;
    }

    &:nth-child(-n + 4) {
      border-top: 1px solid $pagination-list-border-color;
    }

    &:nth-child(4n):last-child,
    &:nth-child(4n):nth-last-child(2),
    &:nth-child(4n):nth-last-child(3),
    &:nth-child(4n):nth-last-child(4) {
      border-bottom-right-radius: 8px;
    }

    &:nth-child(4),
    &:nth-child(3):last-child,
    &:nth-child(2):last-child {
      border-top-right-radius: 8px;
    }

    &:nth-child(4n-3):last-child,
    &:nth-child(4n-3):nth-last-child(2),
    &:nth-child(4n-3):nth-last-child(3),
    &:nth-child(4n-3):nth-last-child(4) {
      border-bottom-left-radius: 8px;
    }

    &:nth-child(4n+4) {
      :deep(.mm-product-card) {
        padding-right: 23px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .list:not(.mobile) {
    .list__item {
      flex: 0 0 33.3%;
      width: 33.3%;

      &:nth-child(3n + 1) {
        border-left: 1px solid $pagination-list-border-color;
      }

      &:nth-child(-n + 3) {
        border-top: 1px solid $pagination-list-border-color;
      }

      &:nth-child(3n):last-child,
      &:nth-child(3n):nth-last-child(2),
      &:nth-child(3n):nth-last-child(3) {
        border-bottom-right-radius: 8px;
      }

      &:nth-child(3),
      &:nth-child(2):last-child {
        border-top-right-radius: 8px;
      }

      &:nth-child(3n-2):last-child,
      &:nth-child(3n-2):nth-last-child(2),
      &:nth-child(3n-2):nth-last-child(3) {
        border-bottom-left-radius: 8px;
      }
    }
  }

  .list.mobile {
    .list__item {
      flex: 0 0 50%;
      width: 50%;

      :deep(.mm-product-card) {
        border-radius: 0;
      }

      &:first-child {
        border-top-left-radius: 8px;
        overflow: hidden;
      }

      &:nth-child(odd) {
        border-left: 1px solid $pagination-list-border-color;
      }

      &:nth-child(-n + 2) {
        border-top: 1px solid $pagination-list-border-color;
      }

      &:nth-child(2) {
        border-top-right-radius: 8px;
        overflow: hidden;
      }

      &:nth-child(2n-1):last-child,
      &:nth-child(2n-1):nth-last-child(2) {
        border-bottom-left-radius: 8px;
        overflow: hidden;
      }

      &:nth-child(even):last-child,
      &:nth-child(even):nth-last-child(2) {
        border-bottom-right-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .list.list--show-more {
    .list__item {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .list__item {
    flex: 0 0 50%;
    max-width: 50%;

    &:first-child {
      border-top-left-radius: 8px;
      overflow: hidden;
    }

    &:nth-child(odd) {
      border-left: 1px solid $pagination-list-border-color;
    }

    &:nth-child(even) {
      border-left: 1px solid $pagination-list-border-color;
    }

    &:nth-child(-n + 2) {
      border-top: 1px solid $pagination-list-border-color;
    }

    &:nth-child(2) {
      border-top-right-radius: 8px;
      overflow: hidden;
    }

    &:nth-child(2n-1):last-child,
    &:nth-child(2n-1):nth-last-child(2) {
      border-bottom-left-radius: 8px;
      overflow: hidden;
    }

    &:nth-child(even):last-child,
    &:nth-child(even):nth-last-child(2) {
      border-bottom-right-radius: 8px;
      overflow: hidden;
    }

    :deep(.mm-product-card) {
      border-radius: 0;
    }
  }

  .list.mobile {
    .list__item {
      flex: 0 0 100%;
      width: 100%;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 0;
        overflow: hidden;

        & + .list__item {
          border-top-right-radius: 8px;
        }
      }

      &:nth-child(1n+2) {
        border-left: 1px solid $pagination-list-border-color;
        border-right: 1px solid $pagination-list-border-color;
      }

      &:nth-child(odd) {
        border-left: 1px solid $pagination-list-border-color;
      }

      &:nth-child(even) {
        border-left-width: 0;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 1px solid $pagination-list-border-color;
        overflow: hidden;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .list__container {
    //display: grid;
    //grid-template-columns: 200%;
  }
}

@media only screen and (max-width: 340px) {
  .list__item {
    width: 100%;
  }
}

</style>
